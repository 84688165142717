.browser {
  margin-top: 50px;
  > .content {
    max-width: 1100px;
    height: 100%;
    display: flex;

    > .main-column {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
      > .title {
        color: #223b6a;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      &.full-width {
        width: 100%;
      }

      &:not(.full-width) {
        width: calc(100% - 190px);
        margin-left: 190px;
      }

      > .filter-and-sort {
        display: none;
      }

      > .resources:not(.see-all) {
        overflow: hidden;
      }

      > .collections:not(.see-all) {
        overflow: hidden;
      }

      > .subjects {
        > .content > .element {
          max-width: 100%;
          width: auto;
        }

        &:not(.see-all) {
          overflow: hidden;
        }
      }

      > .people:not(.see-all) {
        overflow: hidden;
      }

      > .load-more {
        display: flex;
        justify-content: center;
      }
    }

    > .side-column {
      position: fixed;
      width: 165px;
      height: 100%;

      > .filter-card {
        margin-bottom: 15px;
      }
    }

    @media screen and (max-width: 800px) {
      > .main-column {
        margin-bottom: 20px;
        &:not(.full-width) {
          margin-left: 0;
          width: 100%;
        }

        > .filter-and-sort {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
        background-color: yellow;
      }
    }
  }

  @media (min-width: 450px) and (max-width: 1000px) {
    .collections > .content > .collection-card {
      font-size: 15px;
    }
  }

  @media screen and (max-width: 450px) {
    .search > .content {
      margin: 25px;
      .collections > .content > .collection-card {
        font-size: 15px;
        width: 100%;
      }
    }
  }
}
