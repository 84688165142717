.card {
  border-radius: 16px;
}

:not(.no-card).card {
  width: 100%;
  background-color: #fff;
  // overflow: hidden;
  // box-shadow: 0 0 6px #dedede;
  border-radius: 16px;
  box-shadow: 0 0 6px var(--color-light-gray-11);

  @media (max-width: 450px) {
    &.hide-border {
      box-shadow: none;
      background-color: transparent;
    }
    &.remove-padding {
      padding: 0;
    }
  }

  &.hover:hover {
    box-shadow: 0px 1px 2px 0px rgb(36 39 41 / 32%),
      0px 2px 6px 2px rgb(41 44 46 / 15%);
  }
}

.no-card {
  border-radius: 0;
}
