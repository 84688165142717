.floating-menu {
  position: relative;

  > .hover-element {
    cursor: pointer;
    position: relative;
    float: right;
    > * {
      display: flex;
      &:focus {
        outline: none;
      }
    }
  }

  > .menu {
    visibility: hidden;
    display: none;
    position: absolute;
    width: max-content;
    display: flex;
    right: -40px;
    padding-right: 32px;
    padding-top: 6px;
    z-index: 3;

    > .content {
      background-color: white;
      border-radius: 9px;
      padding: 8px 0;

      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      > div > * {
        padding: 7px 15px;
        cursor: pointer;
        text-decoration: inherit;
        color: inherit;
        font-size: 14px;
        font-weight: normal;
        font-family: inherit;
        display: flex;
        align-items: center;
        color: #4d5156;

        &:hover {
          background-color: #f1f3f4;
        }

        &:active {
          background-color: #e6effc;
        }

        > svg {
          margin-right: 14px;
          color: #a7a7a7;
          fill: #a7a7a7;
        }
      }
    }
  }
}

.menu.visible
// ,.floating-menu:hover .menu,
// .floating-menu:active .menu
{
  visibility: visible;
}
