.add-to-collections-card {
  width: 100%;

  > .content {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > :not(.no-card).card {
      padding: 22px 28px 28px;
    }

    > .card {
      height: fit-content;

      > .collections-header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 12px;

        > .searchbox {
          > .search-box {
            flex-direction: row-reverse;
            width: 100%;
            border: none;
            height: fit-content;
            > .search-text {
              text-align: right;
              margin-right: 8px;
              font-size: 14px;
            }
          }
          @media (max-width: 450px) {
          }
        }
      }

      > .collections {
        flex-wrap: wrap;
        width: 100%;
        height: 100%;

        > .collection-name {
          margin: 7px 10px 7px 3px;
        }

        > .selected {
          background-color: #1a6aff;
          color: white;
          &:hover {
            background-color: #2b69db;
          }
          &:active {
            background-color: #265dc5;
          }
        }
      }
    }

    @media screen and (max-width: 800px) {
    }
  }
}
