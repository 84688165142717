@import '../../../../styles/application';

.resource-card {
  position: relative;
  border-radius: 10px;

  &.is-private {
    opacity: 1;
  }

  > .resource-card-header {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    left: 25px;
    right: 25px;
    top: 18px;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    * {
      border-radius: 16px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .left-side {
      > .type {
        pointer-events: none;
        padding: 4px 10px;
        width: 100%;
        max-width: fit-content;
        color: white;
      }
    }

    > .right-side {
      margin-right: -10px;
      flex-grow: 0;
      flex-shrink: 0;
      > .delete {
        pointer-events: all;
        height: 26px;
        width: 25px;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        > svg {
          font-size: 20px;
        }
      }
    }

    > .level {
      width: fit-content;
      background: #ffffffd6;
      flex: 1;
      > .name {
        padding: 4px 10px;
        color: #424242;
      }
    }
    &.horizontal {
      right: 22px;

      &.tiny,
      &.small {
        left: 120px;
      }

      &.medium {
        left: 170px;
      }

      &.big {
        left: 200px;
      }

      > .level {
        width: fit-content;
        color: #424242;
        background: #efefefd6;
      }
    }
  }

  > .resource-card-footer {
    justify-content: space-between;
    position: absolute;
    pointer-events: none;
    left: 25px;
    right: 25px;
    height: 20px;
    bottom: 18px;
    z-index: 1;
    display: flex;
    gap: 10px;
    font-size: 13px;
    font-family: 'Lato';
    font-size: 12px;
    letter-spacing: 0.2px;
    color: white;

    &.horizontal {
      left: 170px;
      right: 22px;
      color: #8c8e95;
      > .left-side > a > span {
        color: #8c8e95;
      }
      &.tiny,
      &.small {
        left: 120px;
      }

      &.medium {
        left: 170px;
      }

      &.big {
        left: 200px;
      }
    }

    > .left-side {
      &:hover {
        text-decoration: underline;
      }
      > a {
        display: flex;
        align-items: center;
        gap: 12px;
        > .avatar {
          height: 22px;
          width: 22px;
          border-radius: 50%;
        }
        > span {
          color: #c1c1c1;
          width: calc(100% - 34px);
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: normal;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
      > :not(.disabled) {
        pointer-events: auto;
      }
    }

    > .right-side {
      gap: 8px;
      > * {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      pointer-events: auto;

      > .disabled {
        cursor: auto;
      }

      > .visibility {
        &.public {
          color: $primary-color;
          &:hover {
            color: $primary-color-hover;
          }
        }
        &.private {
          &:hover {
            color: #63656a;
          }
        }
      }

      > .liked {
        &:not(:hover) > svg {
          color: #fc0071;
        }
        &:hover,
        > svg:hover {
          color: #ce005d;
        }
      }

      > .like {
        &.disabled {
          color: #d0d1db;
        }
      }
      > .like:hover:not(.disabled) {
        color: #ce005d;
      }

      > .bookmarked {
        color: #00bd7e;
      }

      > .bookmark:hover:not(.disabled) {
        color: #009966;
      }
    }

    > * {
      cursor: pointer;
      display: flex;
      align-items: center;
      > svg,
      * > svg {
        width: 18px;
        height: 18px;
      }
      &.disabled {
        color: #919191;
      }
    }
  }

  > a,
  > .content-container {
    width: 100%;

    > .content {
      position: relative;
      cursor: pointer;
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: flex-start;

      > .image {
        object-fit: cover;
        border-radius: 9px 0 0 9px;
        &.tiny,
        &.small {
          width: 100px;
        }

        &.medium {
          width: 150px;
        }

        &.big {
          width: 180px;
        }
      }

      > .resource-card-content {
        width: 100%;
        overflow: hidden;
        flex-direction: column;
        display: flex;
        gap: 4px;
        height: 100%;
        gap: 8px;

        > .title > span {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-family: 'Lato';
          line-height: 145%;
          letter-spacing: 0.2px;
          overflow-wrap: break-word;
        }
        &.horizontal {
          &.tiny,
          &.small {
            width: calc(100% - 100px);
          }

          &.medium {
            width: calc(100% - 165px);
          }

          &.big {
            width: calc(100% - 180px);
          }

          > .title > span {
            -webkit-line-clamp: 1;
            font-size: 18px;
          }
        }

        &.vertical {
          justify-content: flex-end;
          height: 100%;
          padding: 0 25px 58px 25px;
          > .title {
            position: relative;
            color: white;
            height: 87px;
            font-size: 20px;
            > span {
              position: absolute;
              -webkit-line-clamp: 3;
              height: fit-content;
              max-height: 100%;
              bottom: 0;
            }
          }
        }
        // > .title {
        //   text-overflow: ellipsis;
        //   white-space: nowrap;
        //   overflow: hidden;
        //   font-family: 'Lato';
        //   font-style: normal;
        //   font-weight: 700;
        //   font-size: 20px;
        //   letter-spacing: 0.2px;
        // }
      }
    }
  }

  > .round-button {
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 2;
    padding: 2px 2px 6px 6px;
    background: white;
    margin: 7px 7px 3px;
  }

  > .tags-row {
    position: absolute;
    bottom: 2px;
    margin-left: 110px;
    z-index: 2;
    display: flex;
    > .tag {
      margin: 0;
    }
    &.horizontal {
      width: calc(100% - 225px);
      margin-left: 200px;
      top: 22px;
      > .tags {
        > a > .tag {
          background: #e5e5e5;
        }
      }
    }

    &.vertical {
      // width: calc(100% - 6px);
      left: 0px;
      right: 20px;
      margin-left: 25px;
      bottom: 132px;
    }

    > .tags {
      margin-top: -3px;
      padding-left: 5px;
      // gap: 6px;
      // padding: 5px 7px 8px 1px;
      -webkit-mask-image: linear-gradient(
        90deg,
        transparent 0%,
        black 3%,
        black 97%,
        transparent 100%
      );
      mask-image: linear-gradient(
        90deg,
        transparent 0%,
        black 3%,
        black 97%,
        transparent 100%
      );

      // &:not(.editing) {
      //   width: calc(100% - 124px);
      // }

      // &.editing {
      //   width: calc(100% - 151px);
      // }

      &.disabled {
        pointer-events: none;
      }

      &.horizontal {
        > a > .tag {
          background: #e5e5e5;
          color: #282828;
        }
      }

      > a > .tag {
        background: rgba(255, 255, 255, 0.9);
        color: #282828;
        &:hover {
          box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 50%);
          background-color: darken(white, 15);
        }

        &:active {
          box-shadow: 0px 1px 2px 0px rgb(60 64 67 / 70%);
          background-color: darken(white, 25);
        }
      }
    }
  }

  &.horizontal {
    height: fit-content;
    > a > .content,
    > .content-container > .content {
      display: flex;
      > .resource-card-content {
        padding: 66px 20px 52px 20px;
      }
    }
  }

  &.vertical {
    width: 100%;
    height: 355px;
    position: relative;

    > a,
    > .content-container {
      width: 100%;
      height: 100%;
    }

    > a > .content,
    > .content-container > .content {
      width: 100%;
      height: 100%;
      flex-direction: column;
      gap: 7px;

      > .image {
        width: 100%;
        max-width: 100%;
        height: 40px;
        border-radius: 16px 16px 0 0;
      }
    }
  }
}
