.header {
  z-index: 5;
  position: fixed;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  top: 0;
  height: 60px;
  width: 100%;
  font-size: 0.7rem;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  background-color: var(--header-background);

  @media screen and (max-width: 675px) {
    padding: 0 25px;
    > .content {
      gap: 15px;
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .left {
      display: flex;
      align-items: center;
    }

    a {
      text-decoration: none;
    }

    .text {
      color: #757575;
      font-weight: bold;
      font-size: 25px;
      margin-left: 10px;
    }

    .center {
      width: 100%;
      max-width: 350px;
      > .searchbox {
        width: 100%;
      }
    }

    > .right {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 6px;

      > .add-menu > .hover-element {
        &:hover > .add-icon > g > path {
          fill: #d8700e;
        }
      }

      > .avatar-menu {
        > .hover-element {
          > .avatar {
            height: 36px;
            width: 36px;
            border-radius: 50%;
            &:hover {
              box-shadow: 0px 1px 2px 0px rgb(60 64 67);
              opacity: 0.9;
            }
          }
        }
        > .menu > .content > * {
          > .profile > .avatar {
            border-radius: 50%;
            height: 28px;
            width: 28px;
            margin-right: 10px;
          }
        }
      }

      .signin-btn {
        > .floating-menu {
          > .menu {
            display: none;
          }
          > div > a > .primary-button {
            svg {
              display: none;
            }
          }

          @media screen and (max-width: 450px) {
            > div > a > .primary-button {
              padding: 10px 3px;
              margin-left: 6px;
              > span {
                display: none;
              }
              > svg {
                display: block;
              }
            }
            > .menu {
              display: block;
            }
          }
        }
      }

      .signup-btn {
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
    }
  }

  .add-icon {
    max-height: 36px;
    > g > path {
      fill: var(--primary-color);
    }
  }
}
