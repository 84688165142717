.modal-portal {
  > .modal-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    > .modal {
      position: relative;
      display: flex;
      gap: 15px;
      flex-direction: column;
      width: fit-content;
      padding: 25px;
      margin: 20px;

      > .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .title {
          font-size: 15px;
          font-weight: bold;
        }

        > .close-button {
          cursor: pointer;
          display: flex;
        }
      }

      > .content {
        width: 100%;

        > img {
          height: auto;
          width: auto;
          max-width: calc(90vw - 50px);
          max-height: calc(90vh - 50px);
        }
      }

      > .actions {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }
}
