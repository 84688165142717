@import '../../../../styles/application';

.create-collection {
  width: 100%;

  > .title {
    font-size: 22px;
    font-weight: bold;
    padding: 30px 0 20px 0;
  }

  > .content {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > .main-column {
      width: 60%;
      height: auto;
      display: flex;
      flex-direction: column;

      > .small-screen-details {
        display: none;
      }

      > .card-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 8px;
      }

      > .card {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;

        > .main-container {
          height: 100%;
          width: 100%;
          min-height: 200px;

          > .uploader {
            width: 100%;
            height: 100%;
            border: 4px dashed #d1d4e0;
            border-radius: 15px;
            cursor: pointer;

            &:hover {
              border: 4px dashed #1a6aff;
              color: #1a6aff;
              background-color: #337cf93f;

              > .upload {
                > span {
                  color: #1a6aff;
                }
                > svg > path {
                  fill: #1a6aff;
                }
              }
            }

            > .upload {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              gap: 10px;

              &.image > svg {
                height: 65px;
              }

              > span {
                font-weight: bold;
                font-size: 15px;
                color: $primary-color;
                text-align: center;
                padding: 0 18px;
              }
            }
          }
          > .image-container {
            display: flex;
            justify-content: flex-end;
            border-radius: 15px;
            height: 100%;
            width: 100%;

            > .delete-image {
              cursor: pointer;
              height: fit-content;
              svg {
                margin: 12px;
                border: 1px solid #e4e5eb;
                box-sizing: border-box;
                border-radius: 38px;
                padding: 4px;
                background: #ffffff;
                box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
                color: #9598a8;

                &:hover {
                  color: #545761;
                  background: rgb(223, 223, 223);
                }
              }
            }
          }
        }
      }
    }

    > .side-column {
      width: 37%;
      > div {
        width: 100%;
        height: 100%;
        padding-bottom: 2px;
        display: inline-flex;
        flex-direction: column;
        gap: 17px;
        > .input-text-field:nth-child(2) {
          height: 100%;
          .textarea-container {
            height: 100%;
          }
        }
      }
    }

    > .main-column > .small-screen-details > div > .dropdown > .input-container,
    > .side-column > div > .dropdown > .input-container {
      min-height: 44px;
    }

    @media screen and (max-width: 800px) {
      > .main-column {
        width: 100%;
        height: 100%;
        height: fit-content;

        > .card {
          height: 300px;
        }

        > .small-screen-details {
          display: block;
          padding: 30px 0 0 0;
          > div {
            gap: 20px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 30px 0 40px 0;

    > .primary-button {
      display: grid;
      grid-template-columns: 1fr;
      > div {
        grid-row-start: 1;
        grid-column-start: 1;

        &.loading {
          display: flex;
          visibility: visible;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
      &.loading {
        pointer-events: none;
      }
    }
  }
}
