.contributor-card {
  width: 100%;
  height: fit-content;
  padding: 18px;
  display: flex;
  align-items: center;
  gap: 15px;

  > a {
    display: flex;
    > .avatar {
      cursor: pointer;
      border-radius: 75px;
      width: 75px;
      height: 75px;
      object-fit: cover;
    }
  }

  > .description {
    font-weight: bold;
    font-size: 19px;
    color: #757575;
    > a {
      color: #212529;
    }

    @media (min-width: 800px) and (max-width: 950px) {
      font-size: 14px;
    }
    @media (min-width: 950px) and (max-width: 1100px) {
      font-size: 16px;
    }
  }
}
