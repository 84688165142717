@import '../../../../styles/application';

.signup-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (max-height: 700px) {
    height: min-content;

    > .signup-content {
      margin-top: 2%;
    }
  }

  &.success {
    align-items: center;
  }

  > .success {
    &.signup-content {
      display: none;
    }
    &.success-content {
      display: flex;
    }
  }

  > .signup-content {
    display: flex;
    height: 100%;
    margin-bottom: 120px;
    margin-right: calc((100vw - 525px) / 2);
    align-items: center;

    @media (max-width: 650px) {
      justify-content: center;
      padding: 0 25px;
      margin-right: 0;
    }

    @media (max-height: 750px) {
      margin-bottom: 5%;
    }

    > .card {
      height: 100%;
    }
    > .card:last-child,
    .card:only-child {
      width: 525px;
      height: min-content;
      min-height: 400px;
      background-color: #b6bacb;

      @media (max-width: 900px) {
        max-width: 525px;
        width: 100%;
      }

      > .content {
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        padding: 53px 65px;
        width: 100%;
        height: 100%;
        gap: 32px;

        @media (max-width: 450px) {
          padding: 53px calc(25px + 5%);
        }

        > .title {
          color: white;
          font-size: 28px;
        }
        > form {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          border-color: transparent;
          gap: 15px;

          > .input-text-field {
            max-width: 300px;
            > .input-container {
              background: none;
              box-shadow: none;
              border-style: solid;
              border-width: 0.5px;
              border-radius: 45px;
              padding: 0 25px;
              height: 46px;
              background-color: white;
              border: none;
              > input {
                padding: 0;

                &::placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                  opacity: 1;
                }
                &::-ms-input-placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                }
                &:-ms-input-placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                }
              }
            }

            &.focused > .input-container {
              box-shadow: 0 0 5px #fff;
              border: 0.5px solid #b6bacb;
              color: #9598a7;
            }

            &:not(.focused) > .input-container {
              color: #b6bacb;
            }

            > .error-msg {
              color: red;
            }

            &.highlight > .input-container {
              box-shadow: 0 0 0 2px red;
              border: none;
            }
          }

          @media (max-height: 425px) {
            gap: 15px;

            > .error {
              position: relative;
              bottom: 0;
            }
          }
        }

        > .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > .left {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            @media (max-width: 450px) {
              flex-direction: column-reverse;
              gap: 5px;
            }
            > a > .tertiary-button {
              color: white;
              white-space: normal;
              &:hover {
                background-color: grey;
              }
            }
          }
          > .right {
            display: flex;
            > .icon {
              cursor: pointer;
              height: 33px;
              width: 33px;
              padding: 3px;
              border-radius: 50px;
              background-color: #fff;
              box-shadow: 0 0 6px #dedede;
              display: flex;
              justify-content: center;
              align-items: center;
              > img {
                height: 55%;
              }
            }

            > .icon:first-child {
              margin-right: 10px;
            }
          }
        }
      }
    }
    > .card:first-child {
      max-height: 340px;
      cursor: pointer;
      margin-right: 30px;
      flex: 1;
      min-width: 185px;
      border-radius: 0 16px 16px 0;
      background-color: white;
      padding-left: 30px;

      &:active {
        background-color: #e6effc;
      }

      @media (max-width: 650px) {
        display: none;
      }

      > a {
        color: #b6bacb;
        font-size: 28px;
        justify-content: center;
        display: flex;
        align-items: flex-end;
        padding-right: 30px;
        flex-direction: column;
        width: 100%;
        height: 100%;

        > svg {
          -webkit-transform: rotate(225deg);
          -ms-transform: rotate(225deg);
          transform: rotate(225deg);
          margin-top: 20px;
          width: 43px;
        }
      }
    }
  }

  > .success-content {
    display: none;
    align-items: center;
    margin-right: 0;
    height: 100%;
    margin-bottom: 120px;

    @media (max-width: 650px) {
      padding: 0 25px;
    }

    @media (max-height: 750px) {
      margin-bottom: 5%;
      margin-right: 0;
    }

    > .card {
      max-width: 390px;
      height: 340px;
      background-color: #b6bacb;
      flex-direction: column;
      justify-content: space-between;
      display: flex;
      padding: 53px 65px;

      > .content {
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .title {
          color: white;
          font-size: 28px;
        }

        > .icon {
          color: white;
          height: 120px;
          width: auto;
        }

        > .subtitle {
          color: white;
          font-size: 20px;
          @media (max-width: 350px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
