@import '../../../../styles/application';

.new-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media (max-height: 450px) {
    height: min-content;

    > .main-content {
      margin-top: 20px;
    }
  }

  > .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    gap: 20px;
    width: 100%;
    max-width: 410px;
    height: 100%;
    margin-bottom: 130px;

    @media (max-width: 650px) {
      padding: 0 25px;
    }

    @media (max-height: 750px) {
      margin-bottom: 5%;
    }

    > .card {
      height: min-content;
      width: 100%;
      min-height: 305px;
      background-color: #b6bacb;

      > .content {
        flex-direction: column;
        justify-content: space-between;
        display: flex;
        padding: 42px 65px;
        width: 100%;
        height: 100%;

        @media (max-width: 450px) {
          padding: 38px calc(25px + 5%);
        }

        > .title {
          color: white;
          font-size: 28px;
        }
        > form {
          position: relative;
          display: inline-flex;
          flex-direction: column;
          justify-content: flex-start;
          border-color: transparent;
          flex-wrap: wrap;
          gap: 14px;

          > .input-text-field {
            max-width: 300px;
            > .input-container {
              background: none;
              box-shadow: none;
              border-style: solid;
              border-width: 0.5px;
              border-radius: 45px;
              padding: 0 25px;
              height: 46px;
              background-color: white;
              border: none;
              > input {
                padding: 0;

                &::placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                  opacity: 1;
                }
                &::-ms-input-placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                }
                &:-ms-input-placeholder {
                  font-weight: 500;
                  font-size: 17px;
                  color: #b6bacb;
                }
              }
            }

            &.focused > .input-container {
              box-shadow: 0 0 5px #fff;
              border: 0.5px solid #b6bacb;
              color: #9598a7;
            }

            &:not(.focused) > .input-container {
              color: #b6bacb;
            }

            > .error-msg {
              color: red;
            }

            &.highlight > .input-container {
              box-shadow: 0 0 0 2px red;
              border: none;
            }
          }

          > .error {
            position: absolute;
            bottom: -46px;
            padding: 8px 18px;
            width: fit-content;
            font-size: 14px;
            color: white;
            align-items: center;
            display: flex;
            background-color: $error-color;
            border-radius: 20px;
            height: 35px;
          }
        }
        > .bottom {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
