@import '../../../../styles/application';

.collection-card {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 185px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px #dedede;
  display: flex;
  flex-direction: column;
  background-size: cover;

  &.is-private {
    opacity: 1;
  }

  > .collection-card-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    transform-origin: top right;
  }

  > .collection-card-header {
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-between;
    pointer-events: none;
    padding: 19px;
    color: white;
    z-index: 1;

    > * {
      font-size: 12px;
      display: flex;
      align-items: center;
      > * {
        display: flex;
        gap: 5px;

        &:hover:not(.disabled) {
          color: rgb(199, 199, 199);
        }
        &:active:not(.disabled) {
          color: rgb(146, 146, 146);
        }
      }

      > svg,
      * > svg {
        width: 18px;
        height: 18px;
      }
    }

    > .left {
      > .num-resources {
        pointer-events: none;
        > svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .right {
      display: flex;
      gap: 5px;
      pointer-events: auto;

      > .visibility {
        &.public {
          color: $primary-color;
          &:hover,
          &:active {
            color: $primary-color-active;
          }
        }
        &:hover,
        &:active {
          color: rgb(199, 199, 199);
        }
      }

      > .bookmark {
        &.bookmarked,
        &:hover:not(.disabled) {
          color: #00bd7e;
        }

        &.bookmarked:hover {
          color: #009966;
        }
      }

      > .follow:hover:not(.disabled) {
        color: $primary-color;
      }

      > .follow {
        padding: 1px 3px;
        font-size: 13px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 4px;
        > svg,
        * > svg {
          width: 18px;
          height: 18px;
        }
        &.disabled {
          color: #d0d1db;
          cursor: auto;
        }

        &:hover:not(.disabled) {
          color: #cbcdd7;
        }
        &.following {
          &:hover {
            &,
            > svg {
              color: $primary-color-hover;
            }
          }
          > svg {
            color: $primary-color;
          }
        }
      }

      > :not(.disabled) {
        cursor: pointer;
      }
    }
  }
  > .collection-card-content {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    padding: 15px 25px 0 25px;

    > .title {
      text-align: center;
      text-align: center;
      font-family: 'Lato';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      color: white;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-wrap: break-word;
    }
  }
}
