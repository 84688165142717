.subject-card {
  cursor: pointer;
  width: 100%;
  height: 100px;
  border-radius: 16px;
  box-shadow: 0 0 6px #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  color: #636363;
  &.card {
    background-color: #e3e5ef;
  }

  @media (max-width: 450px) {
    margin-top: 0;
  }

  > .title {
    width: 100%;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    display: inline-block;
    > abbr {
      text-decoration: none;
    }
  }

  .subtitle {
    display: flex;
    align-items: center;
    margin-top: 10px;

    > .url {
      font-weight: normal;
      font-size: 17px;
    }

    > .color {
      width: 26px;
      height: 13px;
      margin-left: 17px;
      border-radius: 7px;
    }
  }
}
