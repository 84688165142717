@import '../../../styles/application';

.resource {
  z-index: 10;

  @media screen and (max-width: 450px) {
    background-color: white;
  }

  > .content {
    z-index: 3;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
      margin-top: 0;
      padding: 0;
      > .main-column {
        padding: 34px;
      }
    }

    @media screen and (min-width: 450px) {
      > .main-column {
        > .main-resource-card {
          padding: 26px 34px 34px 34px;
        }
      }
    }
    @media (min-width: 450px) and (max-width: 800px) {
      > .main-column {
        gap: 15px;
        > .resource-footer {
          > * {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 50%;
          }
          > .one-column {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 450px) {
      > .main-column {
        > .main-resource-card {
          padding-top: 0;
          padding-bottom: 0;
        }
        > .resource-footer {
          > :not(.one-column) {
            display: none;
          }
          .one-column {
            display: flex;
            flex-direction: column;
            gap: 30px;
          }
        }
      }
    }

    > .main-column {
      gap: 30px;
      width: 65%;
      height: 100%;

      > .main-resource-card {
        display: flex;
        flex-direction: column;
        gap: 22px;
        position: relative;

        > .info-card {
          display: flex;
          padding: 0;
          box-shadow: none;
        }

        > .resource-header {
          display: flex;
          width: 100%;
          height: fit-content;
          flex-direction: column;
          gap: 8px;

          .type-and-actions {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            > * {
              display: flex;
            }
            > .resource-type {
              flex: 1 1 auto;
              overflow: hidden;
              font-size: 19px;
              font-weight: bold;
              align-items: center;
              gap: 10px;
              > .type {
                border-radius: 16px;
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                pointer-events: none;
                padding: 2px 10px;
                width: 100%;
                height: fit-content;
                max-width: -moz-fit-content;
                max-width: fit-content;
                color: white;
              }
            }
            > .actions {
              display: flex;
              flex: 1 0 auto;
              gap: 12px;
              justify-content: flex-end;

              > * {
                display: flex;
                align-items: center;
                gap: 2px;
                font-size: 15px;
                cursor: pointer;
                color: #8c8e95;
                > svg,
                * > svg {
                  width: 24px;
                  height: 24px;
                }
              }

              > .more-button > .hover-element > .more {
                font-size: 20px;
                padding-bottom: -11px;
                padding: 0 8px 11px 8px;
              }

              > .like {
                > svg {
                  width: 21px;
                  height: 21px;
                }

                &.disabled {
                  pointer-events: none;
                  cursor: auto;
                  color: #d0d1db;
                }
              }

              > .liked {
                &:not(:hover) > svg {
                  color: #fc0071;
                }
                &:hover > * {
                  color: #ce005d;
                }
              }

              > .like:hover {
                color: #ce005d;
              }

              .bookmarked {
                color: #00bd7e;
              }

              > .bookmark:hover {
                color: #009966;
              }

              > .share:hover {
                color: $primary-color;
              }

              > .edit-save {
                width: 40px;
                > * {
                  width: 100%;
                  > svg,
                  * > svg {
                    width: 18px;
                    height: 18px;
                  }
                }
                > .primary-button {
                  display: grid;
                  grid-template-columns: 1fr;
                  > div {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    display: flex;

                    &.loading {
                      display: flex;
                      visibility: visible;
                      width: 100%;
                      align-items: center;
                      justify-content: center;
                    }
                  }

                  &.loading {
                    pointer-events: none;
                  }
                }
              }
            }
          }

          > .title {
            &,
            > .textarea-container > textarea {
              font-size: 26px;
              line-height: 30px;
              font-weight: bold;
            }
          }

          .tags {
            gap: 5px;
            > a > .tag {
              font-size: 12px;
            }
          }
        }

        > .resource-header > .input-text-field,
        > .input-text-field {
          > .input-container {
            padding: 0;
            box-shadow: none;
            // animation: none;
          }
        }

        > .image-container {
          position: relative;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 1px;

          & > .loading,
          > a > .loading {
            position: absolute;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
          }

          > .image,
          > a > .image {
            cursor: pointer;
            width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }

          > .image-credits,
          > a > .image-credits {
            bottom: 9px;
            padding: 2px 7px;
            border-radius: 6px;
            right: 5px;
            display: flex;
            justify-content: flex-end;
            font-size: 11px;
            gap: 3px;
            > a:hover {
              text-decoration: underline;
            }
          }

          > .image-actions {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 5;
            padding: 10px;
            display: flex;
            gap: 6px;
            > * {
              &.disabled {
                pointer-events: none;
              }
            }

            // > .change-image-button {
            // }

            > .search-image-button {
              > .content > svg {
                padding: 3px;
              }
              &.highlight {
                > .content > svg {
                  animation: color-change 1.5s ease 0.5s 6;

                  @keyframes color-change {
                    50% {
                      color: white;
                      background-color: #1a6aff;
                      border: 1px solid #3179ff;
                    }
                  }
                }
              }
            }
          }
        }

        > .description {
          font-size: 14px;
          line-height: 20px;
        }

        .bottom {
          display: flex;
          justify-content: flex-end;
          > div {
            width: 40px;
            > svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      > .resource-footer {
        display: none;

        > .one-column {
          width: 100%;

          > .contributor-card {
            @media screen and (max-width: 450px) {
              padding: 0;
            }
          }
        }
      }
    }

    > .side-column {
      width: 32%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      > :first-child:is(.button) {
        width: 100%;
        justify-content: center;
      }
    }

    @media screen and (max-width: 800px) {
      > .main-column {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        > .resource-footer {
          display: flex;
          gap: 15px;
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
      }
    }

    > .side-column > .extra-details-card,
    > .main-column > .resource-footer > * > .extra-details-card {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      gap: 13px;
      flex-wrap: wrap;
      justify-content: center;

      @media screen and (min-width: 450px) {
        padding: 25px;
      }

      > .dropdown,
      > .date > .fields > .dropdown {
        gap: 4px;

        > label {
          margin-bottom: 0;
        }
        > .input-container {
          background-color: transparent;
          border: none;
        }

        &.disabled {
          > .input-container {
            background-color: transparent;
            border: none;
            > .dropdown-button {
              color: #c6c6c6;
            }
          }
        }

        &.enter-error {
          > .input-container {
            box-shadow: 0 0 0 2px red;
          }
        }
      }

      > .detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        > .title {
          font-weight: bold;
          font-size: 14px;
        }
        > .value {
          display: flex;
          align-items: center;
          color: #4d5156;
          fill: #4d5156;
          height: 24px;
          width: 100%;
          line-height: 20px;
          font-weight: 500;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.date {
            display: flex;
            gap: 8px;
          }

          &.icons {
            display: flex;
            align-items: center;
            gap: 6px;
            > div {
              display: flex;
              gap: 3px;
              > * {
                width: 20px;
                height: 20px;
              }
            }
          }
        }

        &.license {
          margin-bottom: 2px;
        }
      }

      > .license-dropdown {
        margin-bottom: 2px;
      }

      > *,
      .date > .fields > * {
        > label {
          margin-bottom: 2px;
        }
        > .input-container {
          min-height: 24px;
          height: 24px;
          box-shadow: none;
          > .dropdown-button {
            padding: 0;
            > .dropdown-pill {
              justify-content: flex-start;
            }
          }
          &.not-editing {
            background-color: transparent;
            border: none;
            color: #c6c6c6;
          }
        }
      }

      > .date {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;
        > label {
          font-weight: bold;
          font-size: 14px;
        }
        > .fields {
          display: flex;
          > :first-child.display-mode.not-editing {
            width: 60px;
          }
        }
        &.disabled {
          color: #c6c6c6;
        }
      }
    }

    .resource-action-card {
      width: 100%;
      height: fit-content;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: 450px) {
        padding: 25px;
      }

      @media screen and (min-width: 1000px) {
        > .button,
        a {
          max-width: 80%;
        }
      }

      > .button,
      > a > .button {
        width: 100%;
        justify-content: center;
        padding: 11px;
        flex: 1;
        gap: 8px;
        height: 40px;

        > svg {
          width: 18px;
        }
      }

      > a {
        width: 100%;
      }
    }
  }
}
