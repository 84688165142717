.tertiary-button {
  color: #687082;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  font-weight: bold;
  font-size: 13.5px;
  letter-spacing: 0.4px;
  height: 32px;
  padding: 0 18px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  &:hover {
    border-radius: 45px;
    background-color: #f1f3f4;
  }

  &:active {
    background-color: #e2e5e5;
  }

  &:focus-visible {
    border: none;
    outline: none;
    transition: 0.1s;
    box-shadow: 0 0 3pt 2pt #1a6affb0;
  }

  &:disabled {
    user-select: none;
    pointer-events: none;
  }
}
