.sort-button {
  > * {
    display: flex;
    justify-content: flex-start;
    gap: 6px;
    padding: 0 16px 0 10px;
    font-size: 14px;
    font-weight: normal;
    > svg {
      width: 24px;
      height: 24px;
    }
    > span {
      line-height: 14px;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
  }

  > .primary-button {
    padding: 0 18px 0 12px;
  }

  &.more > * > svg {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  &.less > * > svg {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

//   &.inactive > .inactive-icon,
//   &.less:hover > .inactive-icon,
//   &.more > .more-icon,
//   &.inactive:hover > .more-icon,
//   &.less > .less-icon,
//   &.more:hover > .less-icon {
//     display: contents;
//   }

//   &.inactive,
//   &.more:hover,
//   &.less:hover {
//     border: none;
//     background-color: white;
//     border: 1px solid #757575;
//     color: #757575;
//   }

//   &.more:hover,
//   &.less:hover {
//     border: none;
//     background-color: #757575;
//     border: 1px solid #757575;
//     color: white;
//   }

//   &.inactive:hover,
//   &.more,
//   &.less {
//     border: none;
//     background-color: #3366ff;
//     border: 1px solid #3366ff;
//     color: white;
//   }
// }
