.extra-details {
  width: 100%;

  > .content {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > .data-inputs {
      width: 100%;
      flex-wrap: wrap;
      height: fit-content;
      display: flex;
      gap: 3%;
      row-gap: 20px;

      > * {
        flex: 0 0 31.3333%;
      }

      > .date {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > label {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 8px;
        }

        > .fields {
          display: flex;
          width: 100%;
          gap: 12px;
          > .month-dropdown {
            width: 100%;
            // flex: 1;
          }
          > .year-dropdown {
            min-width: 85px;
            width: 40%;
          }
        }
      }

      @media (min-width: 400px) and (max-width: 850px) {
        gap: 5%;
        row-gap: 20px;
        > * {
          flex: 0 0 47%;
        }
      }

      @media screen and (max-width: 400px) {
        gap: 0%;
        row-gap: 20px;
        > * {
          flex: 0 0 100%;
        }
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 30px 0 40px 0;
    > .primary-button {
      display: grid;
      grid-template-columns: 1fr;
      > div {
        grid-row-start: 1;
        grid-column-start: 1;

        &.loading {
          display: flex;
          visibility: visible;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      &.loading {
        pointer-events: none;
      }
    }
  }
}
