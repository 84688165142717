@import '../../../styles/utils/colors';

.footer {
  padding: 50px 0 40px 0;
  display: flex;
  justify-content: center;
  top: 0;
  height: 60px;
  width: 100%;
  font-size: 0.7rem;

  @media screen and (max-width: 675px) {
    padding: 25px 0 35px 0;
    > .content {
      gap: 15px;
    }
  }

  > .content {
    height: 100%;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;

    .center {
      display: flex;
      justify-content: center;
      width: 100%;
      .short {
        > a {
          color: #6b6b6b;
          font-size: 15px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .extended {
        text-align: center;
        color: #6b6b6b;
        font-size: 14px;
        > a {
          color: #0a60ff;
          &:hover {
            text-decoration: underline;
          }
        }
        > .love-icon {
          padding-bottom: 6px;
          margin: 0 0 -9px 0;
          color: #fc0071;
        }
      }
    }
  }
}
