@import '../../../styles/application';

.collection {
  @media screen and (max-width: 450px) {
    background-color: white;
  }

  > .content {
    width: 100%;
    max-width: 1100px;
    height: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 450px) {
      margin-top: 0;
      padding: 34px;
    }

    > .main-collection-card {
      display: flex;
      gap: 20px;

      @media (min-width: 450px) {
        > :last-child {
          margin-bottom: 20px;
        }

        > :not(.image) {
          padding: 0 20px 0 20px;
        }
      }

      position: inherit;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 450px) {
        border-radius: 0;
      }

      > .image {
        height: 300px;
        width: 100%;
        position: inherit;
        position: relative;
        cursor: pointer;
        border-radius: 16px 16px 0 0;

        @media (max-width: 450px) {
          border-radius: 0;
          height: 175px;
        }

        > .image-actions {
          // width: fit-content;
          // float: right;
          z-index: 5;
          padding: 10px;
          display: flex;
          justify-content: flex-end;
          gap: 6px;
          > * {
            &.disabled {
              pointer-events: none;
            }
          }

          > .search-image-button {
            > .content > svg {
              padding: 3px;
            }
            &.highlight {
              > .content > svg {
                animation: color-change 1.5s ease 0.5s 6;

                @keyframes color-change {
                  50% {
                    color: white;
                    background-color: #1a6aff;
                    border: 1px solid #3179ff;
                  }
                }
              }
            }
          }
        }

        > .image-credits {
          position: absolute;
          bottom: 9px;
          color: white;
          background: #00000054;
          padding: 2px 7px;
          border-radius: 6px;
          right: 15px;
          display: flex;
          justify-content: flex-end;
          font-size: 11px;
          gap: 3px;
          > a:hover {
            text-decoration: underline;
          }
        }
      }

      // > .image-container {
      //   position: relative;
      //   width: 100%;
      //   display: flex;
      //   flex-direction: column;
      //   gap: 1px;

      //   > .image,
      //   > a > .image {
      //     cursor: pointer;
      //     width: 100%;
      //     height: 100px;
      //     object-fit: cover;
      //     border-radius: 16px 16px 0 0;
      //   }

      // }

      > .info {
        padding: 0 20px 0 20px;
        @media screen and (max-width: 450px) {
          padding: 0;
        }
      }

      > .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        > .input-text-field > .input-text-field-container {
          box-shadow: none;
          animation: none;
        }

        > .label {
          font-size: 19px;
          font-weight: bold;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .actions {
            display: flex;
            gap: 12px;
            width: fit-content;
            align-items: center;
            font-size: 15px;
            font-weight: normal;

            > * {
              cursor: pointer;
              display: flex;
              align-items: center;

              > svg {
                width: 24px;
                height: 24px;
              }
            }

            > .follow {
              padding: 1px 3px;
              cursor: pointer;
              display: flex;
              align-items: center;
              color: #757575;

              &.disabled {
                color: #d0d1db;
                cursor: auto;
              }

              &:hover:not(.disabled) {
                color: #5c5c5c;
              }
              &.following {
                &:hover {
                  &,
                  > svg {
                    color: $primary-color-hover;
                  }
                }
                > svg {
                  color: $primary-color;
                }
              }
            }

            // > .follow {
            //   display: flex;
            //   align-items: center;
            //   gap: 2px;
            //   font-size: 15px;

            //   > .following {
            //     color: $primary-color;
            //   }

            //   > .follow:hover {
            //     color: $primary-color;
            //   }
            // }

            > .more-button > .hover-element > .more {
              font-size: 20px;
              padding-bottom: -11px;
              padding: 0 8px 11px 8px;
            }

            &.edit-save {
              > :last-child {
                width: 40px;
                > svg {
                  width: 18px;
                  height: 18px;
                }
              }

              > .primary-button {
                display: grid;
                grid-template-columns: 1fr;
                > div {
                  grid-row-start: 1;
                  grid-column-start: 1;
                  display: flex;

                  > svg {
                    width: 18px;
                    height: 18px;
                  }

                  &.loading {
                    display: flex;
                    visibility: visible;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                  }
                }

                &.loading {
                  pointer-events: none;
                }
              }
            }

            > .bookmark {
              color: #757575;
            }

            > .bookmarked {
              color: #00bd7e;
            }

            > .bookmark:hover {
              color: #00bd7e;
            }

            > .share:hover {
              color: $primary-color;
            }
          }
        }

        > .title {
          &,
          > .textarea-container > textarea {
            font-size: 26px;
            line-height: 30px;
            font-weight: bold;
          }
        }

        > .description {
          font-size: 14px;
          line-height: 20px;
        }

        > .actions {
          display: flex;
          margin-top: 8px;
          width: 100%;
          height: 32px;
          align-items: center;
          justify-content: space-between;

          > .left {
            display: flex;
            align-items: center;
            gap: 10px;

            > .follow-button {
              > svg {
                width: 20px;
                height: 20px;
              }
            }

            > .following-button {
              gap: 2px;
              > svg {
                width: 17px;
                height: 17px;
              }
            }
          }

          .right {
            > div {
              width: 40px;
              > svg {
                width: 18px;
                height: 18px;
              }
            }
            > .image-credits {
              display: flex;
              width: 100%;
              justify-content: flex-end;
              align-items: center;
              margin-right: 12px;
              font-size: 11px;
              color: #a8afb9;
              gap: 3px;
              > a {
                text-transform: capitalize;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    > .main-content {
      display: flex;
      gap: 20px;

      @media (min-width: 450px) and (max-width: 800px) {
        > .main-column {
          gap: 15px;
          > .collection-footer {
            display: flex;
            gap: 15px;

            > * {
              display: flex;
              flex-direction: column;
              gap: 15px;
              width: 50%;
            }
            > .one-column {
              display: none;
            }
          }
        }
      }

      @media screen and (max-width: 450px) {
        > .main-column {
          > .list-card {
            padding: 0;
          }

          > .collection-footer {
            > :not(.one-column) {
              display: none;
            }
            .one-column {
              display: flex;
              flex-direction: column;
              width: 100%;
              > * {
                padding: 0;
              }

              > .extra-details-card {
                padding: 0;
              }
            }
          }
        }
      }

      > .main-column {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 65%;
        height: 100%;

        > .collection-footer {
          display: none;
        }
      }

      > .side-column {
        width: 32%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        > :first-child:is(.button) {
          width: 100%;
          justify-content: center;
        }
      }

      @media screen and (max-width: 800px) {
        > .main-column {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          > .collection-footer {
            display: flex;
          }
        }

        > .side-column {
          display: none;
          width: 325px;
          height: 100%;
        }
      }

      > .side-column > .extra-details-card,
      > .main-column > .collection-footer > .right-column > .extra-details-card,
      > .main-column > .collection-footer > .one-column > .extra-details-card,
      > .side-column > .extra-details-card {
        width: 100%;
        height: fit-content;
        display: flex;

        flex-direction: column;
        gap: 13px;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (min-width: 450px) {
          padding: 25px;
        }

        > .dropdown {
          gap: 4px;
          &.disabled {
            > .input-container {
              background-color: transparent;
              border: none;
              > .dropdown-button {
                color: #c6c6c6;
              }
            }
          }

          &.enter-error {
            > .input-container {
              box-shadow: 0 0 0 2px red;
            }
          }

          > label {
            margin-bottom: 0;
          }
          > .input-container {
            background-color: transparent;
            border: none;
          }
        }

        > .license-dropdown {
          margin-bottom: 2px;
        }

        > * {
          > label {
            margin-bottom: 2px;
          }
          > .input-container {
            min-height: 24px;
            height: 24px;
            box-shadow: none;
            > .dropdown-button {
              padding: 0;
              > .dropdown-pill {
                justify-content: flex-start;
              }
            }
          }
        }

        > .detail {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;
          > .title {
            font-weight: bold;
            font-size: 14px;
          }
          > .value {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.5);
            fill: rgba(0, 0, 0, 0.5);
            height: 24px;
            width: 100%;
            line-height: 20px;
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.date {
              display: flex;
              gap: 8px;
            }

            &.icons {
              display: flex;
              align-items: center;
              gap: 6px;
              > div {
                display: flex;
                gap: 3px;
                > * {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }

          &.license {
            margin-bottom: 2px;
          }
        }

        > .dropdown:not(:last-child) {
          margin-bottom: 2px;
        }
      }
    }

    .collection-actions-card {
      width: 100%;
      height: fit-content;
      padding: 25px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;

      > .button {
        max-width: 160px;
        justify-content: center;
        padding: 11px;
        flex: 1;
      }
    }
  }
}

.image-modal > .modal > .content {
  > .image-credits {
    bottom: 9px;
    padding: 2px 7px;
    border-radius: 6px;
    right: 5px;
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    gap: 3px;
    > a:hover {
      text-decoration: underline;
    }
  }
}
