.followers {
  > .browser {
    > .content > .main-column > .people {
      gap: 26px;
      > .title > .card-header > .title {
        color: #4d5156;
        font-size: 30px;
      }
    }
  }
}
