.view {
  > .fallback-page {
    height: 100%;
    width: 100%;
    position: relative;
    max-width: 100%;

    > .content {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      font-weight: lighter;
      font-family: 'Open Sans';
      text-align: center;
    }

    > .hat-logo {
      position: absolute;
      height: 180px;
      width: 180px;
      z-index: 1;
      bottom: 48px;
      animation: fallback-move 2s infinite linear,
        fallback-spin 1s infinite linear;
    }

    @keyframes fallback-spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes fallback-move {
      0% {
        left: -180px;
        bottom: 20%;
      }
      50% {
        left: 50%;
        bottom: 0px;
      }
      100% {
        left: calc(100% + 180px);
        bottom: 20%;
      }
    }
  }
}
