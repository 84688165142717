.overall-card {
  width: 100%;
  height: 70px;
  background-color: #fff;
  padding: 0 5px;
  display: flex;
  align-items: center;

  > .overall-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    justify-content: space-around;

    > .data {
      display: flex;
      flex-direction: column;
      align-items: center;
      line-height: 1.4;
      font-weight: bold;
      text-align: center;
      font-size: 13px;

      > abbr > svg {
        height: 0.8em;
        width: 0.8em;
        color: #b6b6b6;
        margin-bottom: 5px;
      }

      > span {
        color: #757575;
      }

      @media (min-width: 450px) and (max-width: 900px) {
        font-size: 12px;
      }

      @media (max-width: 450px) {
        font-size: 11px;
      }
    }
  }
}
