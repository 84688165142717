.round-button > .content {
  cursor: pointer;
  height: fit-content;
  display: flex;

  > svg {
    box-sizing: border-box;
    border-radius: 38px;
    padding: 4px;
    background: #ffffff;

    &.color-gray {
      //box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
      border: 1px solid #e4e5eb;
      color: #9598a8;
    }

    &.color-red {
      border: 1px solid red;
      //box-shadow: 0px 0px 5px rgb(0 0 0 / 5%);
      color: red;
    }

    &:hover {
      &.hover-gray {
        color: #545761;
        background: rgb(223, 223, 223);
      }

      &.hover-red {
        color: red;
        background: transparent;
      }

      &.hover-fill-red {
        color: white;
        background: red;
      }
    }
  }
}
