.small-profile-card {
  width: 100%;
  max-width: 245px;
  height: 254px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  > .images {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > .background {
      cursor: pointer;
      width: 100%;
      height: 70px;
      object-fit: cover;
      border-radius: 16px 16px 0 0;
    }

    > .avatar > img {
      z-index: 3;
      border: 2px solid white;
      border-radius: 50px;
      width: 100px;
      height: 100px;
      object-fit: cover;
      margin-top: -55px;
    }
  }

  > .info {
    width: 100%;
    padding: 7px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 7px;

    > .profile-card-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      > .title-header {
        display: flex;
        width: 100%;
        white-space: nowrap;
        font-size: 15px;
        font-weight: bold;
        gap: 6px;
        justify-content: center;
        > .title {
          width: fit-content;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            text-decoration: underline;
          }
        }
        > svg {
          min-height: 16px;
          min-width: 16px;
          max-height: 16px;
          max-width: 16px;
        }
      }

      > .subtitle {
        width: 100%;
        margin-top: 3px;
        font-size: 13px;
        color: #7e7e7e;
        justify-content: flex-start;
        gap: 5px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      > .error {
        padding: 0 18px;
        width: fit-content;
        font-size: 14px;
        color: white;
        align-items: center;
        display: flex;
        background-color: #ff6262;
        border-radius: 20px;
        margin-top: 10px;
        height: 28px;
      }
    }

    > .description,
    .description > .input-container > textarea {
      font-size: 14px;
      line-height: 20px;
    }

    > .buttons {
      width: 100%;

      > * {
        height: 32px;
        width: 100%;
      }
    }

    > .overall-card {
      height: fit-content;

      > .data > span {
        font: 10px;
      }
    }
  }
}
