@import '../../../../styles/utils/colors';

.cookies-policy {
  width: 100%;
  padding: 60px 100px;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 80px;

  @media screen and (max-width: 675px) {
    padding: 40px 25px;
  }

  & * {
    font-family: 'Open Sans';
    font-weight: 400;

    > a {
      color: $base-electric-blue-color;
      &:hover {
        color: $base-moodle-color;
      }
    }
  }

  > h1 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 18px;
  }

  > h2 {
    font-size: 28px;
    margin: 10px 0 18px;
  }

  > h3 {
    font-size: 24px;
    line-height: 30px;
    margin: 10px 0 18px;
  }

  > p {
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 10px;
  }

  > ul {
    padding-inline-start: 40px;
    margin-bottom: 25px;
    font-size: 18px;
    line-height: 28px;
  }

  > table {
    border-collapse: collapse;
    border-spacing: 5em;
    > tbody {
      line-height: 28px;
      > tr {
        > * {
          text-align: left;
          font-size: 18px;
          padding: 5px 8px;
        }
        > th {
          font-weight: bold;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $base-grey-color;
        }

        > :nth-child(2) {
          width: 160px;
        }
      }
    }
  }
}
