@import '../styles/application.scss';

.view {
  z-index: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  background-color: var(--color-light-gray-14);
  background-size: cover;
  overflow-x: hidden;
  bottom: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.view::-webkit-scrollbar {
  display: none;
}

.view > :not(.snackbar) {
  height: fit-content;
  width: 100%;
  max-width: 1100px;

  > * {
    @media (max-width: 450px) {
      padding: 0 25px;
    }
  }
}

.double-header-page {
  padding: 120px 100px 0 100px;

  @media (max-width: 450px) {
    padding: 120px 0 0 0;
  }

  @media screen and (max-width: 675px) and (min-width: 450px) {
    padding: 120px 25px 0 25px;
  }
}

.single-header-page {
  padding: 60px 100px 0 100px;

  @media (max-width: 450px) {
    padding: 60px 0 0 0;
  }

  @media screen and (max-width: 675px) and (min-width: 450px) {
    padding: 60px 25px 0 25px;
  }
}
