@import '../../../../styles/application';

.access-header {
  z-index: 1;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  height: 60px;
  min-height: 60px;
  width: 100%;
  font-size: 0.7rem;

  @media screen and (max-width: 675px) {
    padding: 0 25px;
  }

  .content {
    height: 100%;
    width: 100%;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .buttons {
      display: flex;
      gap: 10px;

      > a {
        > .secondary-button:first-child {
          display: none;
        }
        @media (max-width: 650px) {
          > .secondary-button:first-child {
            display: flex;
          }
        }
      }
    }
  }
}
