.loading {
  display: flex;

  &.dot-flashing {
    position: relative;
    width: 9px;
    height: 9px;
    border-radius: 5px;
    background-color: var(--loading-color);
    color: var(--loading-color);
    animation: dotFlashing 0.5s infinite linear alternate;
    animation-delay: 0.25s;

    .dot-flashing::before,
    .dot-flashing::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot-flashing::before {
      left: -15px;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: var(--loading-color);
      color: var(--loading-color);
      animation: dotFlashing 0.5s infinite alternate;
      animation-delay: 0s;
    }

    .dot-flashing::after {
      left: 15px;
      width: 9px;
      height: 9px;
      border-radius: 5px;
      background-color: var(--loading-color);
      color: var(--loading-color);
      animation: dotFlashing 0.5s infinite alternate;
      animation-delay: 0.5s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: var(--loading-color);
      }
      50%,
      100% {
        background-color: #f881124b;
      }
    }
  }

  &.circular-progress {
    width: fit-content;
  }
}
