@import '../../../../styles/application';

.upload-resource {
  width: 100%;

  > .content {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;

    > .main-column {
      width: 60%;
      height: auto;
      display: flex;
      flex-direction: column;

      > .small-screen-details {
        display: none;
      }

      > .card-title {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 8px;
      }

      > .card {
        height: 100%;
        min-height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;

        > .main-container {
          height: 100%;
          width: 100%;
          display: flex;
          flex: 1;

          > .uploader {
            width: 100%;
            height: 100%;
            border: 4px dashed #d1d4e0;
            border-radius: 15px;
            cursor: pointer;

            &:hover,
            &.hover {
              border: 4px dashed #1a6aff;
              color: #1a6aff;
              background-color: #337cf93f;

              > .upload {
                > span {
                  color: #1a6aff;
                }
                > svg > path {
                  fill: #1a6aff;
                }
              }
            }

            > .upload {
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              gap: 10px;

              &.image > svg {
                height: 65px;
              }

              > span {
                font-weight: bold;
                font-size: 15px;
                color: $primary-color;
                text-align: center;
                padding: 0 18px;
              }
            }

            &.error {
              animation: border-blink 6s ease-out;

              @keyframes border-blink {
                0% {
                  border: 4px dashed #d1d4e0;
                }
                3% {
                  border: 4px dashed $error-color;
                }
                97% {
                  border: 4px dashed $error-color;
                }
                100% {
                  border: 4px dashed #d1d4e0;
                }
              }
            }
          }
          > .image-container {
            display: flex;
            justify-content: flex-end;
            border-radius: 15px;
            height: 100%;
            width: 100%;

            > .round-button {
              margin: 12px;
            }
          }
        }

        > .bottom-container {
          width: 100%;
          height: fit-content;
          display: flex;
          gap: 15px;

          > .license-dropdown {
            max-width: 132px;
          }

          > .subcontainer,
          > .input-text-field,
          .dropdown > input {
            width: 100%;
            height: fit-content;
          }

          > .subcontainer,
          > .input-text-field > input,
          .dropdown > input {
            width: 100%;
            height: fit-content;
            box-shadow: 0 0 6px #dedede;
            border-radius: 10px;
          }

          > .link {
            > .input-container {
              padding: 8px 0;
              height: 100%;
              background-color: transparent;
              > :last-child:not(input) {
                margin-right: 10px;
              }
            }
          }

          > .uploaded-name {
            display: flex;
            height: 46px;
            padding-right: 10px;
            align-items: center;
            font-weight: 500;
            font-size: 15px;
            color: #757575;

            &.file {
              width: calc(100% - 146px);
            }

            &.link {
              width: 100%;
            }
            > .content-icon {
              display: flex;
              align-items: center;
              margin: 0 10px 0 16px;
              color: #757575;
            }
            > abbr {
              margin-right: 5px;
            }
            > .round-button {
              display: flex;
              margin-left: auto;
            }
          }

          > .dropdown {
            width: 130px;
            > .dropdown-content {
              width: auto;
            }
          }
        }
      }
    }

    > .side-column {
      width: 37%;
      > .data-inputs {
        width: 100%;
        height: 100%;
        padding-bottom: 2px;
        display: inline-flex;
        flex-direction: column;
        gap: 17px;
        > .input-text-field:nth-child(2) {
          height: 100%;
          .textarea-container {
            height: 100%;
          }
        }

        @media screen and (max-width: 450px) {
          > .subject-and-visibility {
            display: flex;
            gap: 10px;
          }
        }
      }
    }

    > .main-column
      > .small-screen-details
      > .data-inputs
      > .subject-and-visibility,
    > .side-column > .data-inputs > .subject-and-visibility {
      display: flex;
      gap: 15px;
      > .subject-dropdown {
        max-width: calc(100% - 125px);
        @media (min-width: 800px) and (max-width: 1100px), (max-width: 400px) {
          max-width: calc(100% - 75px);
        }
      }

      > .visibility-dropdown {
        width: 110px;
        @media (min-width: 800px) and (max-width: 1100px), (max-width: 400px) {
          max-width: 60px;
        }
      }
    }

    @media screen and (max-width: 800px) {
      > .main-column {
        width: 100%;
        height: 100%;
        height: fit-content;

        > .card {
          height: 300px;
        }

        > .small-screen-details {
          display: block;
          padding: 30px 0 0 0;
          > .data-inputs {
            gap: 20px;
            display: flex;
            flex-direction: column;
          }
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
      }
    }
  }
  > .footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding: 30px 0 10px 0;
  }
}

.delete-message > .modal {
  > .content {
    font-size: 14px;
    padding: 10px 0;
  }
}
