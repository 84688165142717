@import '../../../styles/application';

.snackbar.card {
  min-height: 40px;
  color: #fff;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 6px 14px;
  margin: 0 20px;
  border-radius: 4px;
  gap: 10px;
  z-index: 11;
  width: fit-content;
  box-shadow: 0px 1px 2px 0px rgb(36 39 41 / 5%),
    0px 2px 6px 2px rgb(41 44 46 / 5%);

  &:not(.inside-snackbar) {
    &.position-top {
      top: 68px;
    }

    &.position-bottom {
      bottom: 20px;
    }

    position: fixed;
  }

  > * {
    display: flex;
  }

  .content {
    padding-right: 8px;
  }

  > .close-button {
    cursor: pointer;
    > span {
      padding: 0 10px 0 0;
    }
  }

  &.type-success {
    background-color: $success-color;
  }

  &.type-error {
    background-color: $error-color;
  }

  &.type-warning {
    background-color: $warning-color;
  }

  &.type-info {
    background-color: $info-color;
  }

  &.state-opening {
    animation: fadeIn 0.5s forwards;
    text-align: center;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.state-closing {
    animation: fadeOut 0.2s forwards;
    text-align: center;
  }

  &.state-closed {
    display: none;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

.view > .snackbar-stack,
.snackbar-stack {
  z-index: 9;
  display: flex;
  gap: 12px;
  position: fixed;
  align-items: center;
  height: fit-content;
  flex-direction: column;
  width: 100%;

  &.position-top {
    top: 68px;
  }

  &.position-bottom {
    bottom: 20px;
  }
}
