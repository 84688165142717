.header-title {
  display: flex;
  align-items: center;
  text-decoration: inherit;

  a {
    text-decoration: none;
  }

  .logo {
    height: 28px;
  }

  .text {
    color: #757575;
    font-weight: bold;
    font-size: 25px;
    margin-left: 10px;
    text-decoration: none;
  }

  > .small {
    display: none;
    height: 38px;
  }

  @media screen and (max-width: 600px) {
    > .big {
      display: none;
    }
    > .small {
      display: block;
    }
  }
}
