.landing {
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding-top: 30px;

  @media screen and (max-width: 900px) {
    padding-top: 0;
  }

  > .landing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    padding: 110px 60px;
    border-radius: 40px;

    @media screen and (max-width: 650px) {
      gap: 25px;
      padding: 60px 25px 70px 25px;
    }

    @media screen and (max-width: 900px) {
      border-radius: 0;
    }

    @media screen and (min-width: 450px) and (max-width: 675px) {
      margin: 0 -25px;
    }
    @media screen and (min-width: 675px) and (max-width: 900px) {
      margin: 0 -100px;
    }

    @media screen and (min-width: 675px) and (max-width: 900px) {
      gap: 45px;
      padding: 80px 80px 90px 80px;
    }

    > .landing-title {
      display: inline-flex;
      max-width: 900px;
      flex-wrap: wrap;
      flex-direction: column;
      text-align: center;
      font-family: Open;
      gap: 25px;
      color: white;
      font-family: 'Open Sans';
      @media screen and (max-width: 900px) {
        gap: 12px;
      }

      > .title {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
      }
      > .subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        > .title {
          font-size: 24px;
        }
        > .subtitle {
          font-size: 16px;
        }
      }

      @media screen and (min-width: 500px) and (max-width: 900px) {
        > .title {
          font-size: 33px;
        }
        > .subtitle {
          font-size: 18px;
        }
      }
    }

    > .searchbox {
      max-width: 585px;
    }

    > .share-content {
      padding: 25px 60px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  > .list-card {
    gap: 46px;
    > .title > .card-header {
      display: flex;
      justify-content: space-between;

      @media (max-width: 580px) {
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;
        align-items: flex-start;
      }

      > .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;

        > .title {
          font-size: 28px;
          line-height: 34px;
          color: #223b6a;
        }
        > .subtitle {
          font-size: 16px;
          color: #91939f;
        }
      }
      > .more {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 0 26px;
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        border-radius: 50px;
        height: 48px;
      }
    }
  }

  > .resources > .content {
    overflow: hidden;
    // max-height: 735px;
    margin-bottom: -10px;
  }

  > .collections > .content {
    overflow: hidden;
    // max-height: 394px;
    margin-bottom: -10px;
  }

  > .people > .content {
    overflow: hidden;
    // max-height: 262px;
    margin-bottom: -10px;
  }

  > .content {
    width: 100%;
    max-width: 1100px;
    height: max-content;
    display: flex;
    justify-content: space-between;
  }

  > .columns-container {
    display: flex;
    gap: 25px;

    > .main-column {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      > .intro-card {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        line-height: 20px;
        gap: 26px;
        > .description {
          width: 100%;
        }
        > .actions {
          display: flex;
          gap: 15px;
        }
      }
    }
    @media screen and (max-width: 800px) {
      > .main-column {
        width: 100%;
        height: max-content;
        .trend-card {
          display: block;
          > .content {
            > .gradient-bar {
              padding-bottom: 78px;
            }
            @media screen and (max-width: 450px) {
              margin-left: 0;
            }
          }
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
      }
    }
  }
}

.text-image {
  width: 100%;
  height: 137px;
  object-fit: cover;
  border-radius: 9px;
}

.modal-portal > .create-content-modal.modal-container {
  > .modal {
    > .modal-header {
      > .title {
        font-size: 16px;
      }
    }
    > .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      > a {
        > .primary-button {
          height: 70px;
          display: flex;
          justify-content: flex-start;
          gap: 15px;
          > .content {
            display: flex;
            flex-direction: column;
            gap: 4px;
            > .title {
              font-size: 15px;
            }
            > .subtitle {
              font-size: 13px;
              color: #707070;
              font-weight: normal;
            }
          }
          > svg {
            width: 30px;
            height: 30px;
            fill: #a7a7a7;
          }
        }
      }
    }
  }
}
