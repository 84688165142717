.profile {
  > .content {
    width: 100%;
    max-width: 1100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    @media screen and (max-width: 450px) {
      margin-top: 0;
      padding: 0;

      > .main-column {
        background-color: white;

        > .list-card {
          padding: 15px 40px;
        }

        > :first-child {
          padding-bottom: 0;
        }

        > :last-child {
          margin-bottom: 36px;
        }
      }
    }

    > .main-column {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 65%;
      height: 100%;

      > .collections {
        display: none;
      }
    }

    > .side-column {
      width: 32%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      > .collections > .title {
        font-size: 15px;
      }
    }

    @media screen and (max-width: 1000px) {
      > .main-column {
        width: 100%;
        height: 100%;

        > .collections {
          display: flex;
        }
      }

      > .side-column {
        display: none;
        width: 325px;
        height: 100%;
      }
    }
  }
}
