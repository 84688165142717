.filter-card {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 6px #dedede;

  > .title {
    font-size: 14px;
    font-weight: bold;
    width: 45px;
  }

  > .content {
    display: flex;
  }

  &.vertical {
    flex-direction: column;
    padding: 20px;

    > .content {
      margin-top: 16px;
      flex-direction: column;

      > :not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &.horizontal {
    min-height: 40px;
    padding: 10px 20px;
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;

    > .content {
      gap: 15px;
      flex-wrap: wrap;

      > .container {
        flex: 1 1 0px;
      }
    }
  }
}
