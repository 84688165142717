.searchbox {
  border: 1px solid #dbdbdb;
  background-color: white;
  border-radius: 16px;
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  gap: 18px;

  [placeholder] {
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  > svg {
    width: 17px;
    height: 21px;
    margin-top: -4px;
    > g > g > path {
      fill: #757575;
    }
  }

  > .search-text {
    background-color: transparent;
    width: 100%;
    font-size: 15px;
    border: none;
    color: #4d5156;
    &::placeholder {
      color: #757575;
    }
    &:focus-visible,
    &:focus {
      box-shadow: none;
    }
  }

  &.size-big {
    padding: 0 20px;
    border-radius: 45px;
    height: 46px;
    width: 100%;
    max-width: 670px;
    background-color: white;
    > .search-text {
      font-size: 17px;
      width: 100%;
    }

    @media (max-width: 450px) {
    }
  }
}
