$base-light-green-color: #00bd7e;
$base-light-red-color: rgb(255, 49, 49);
$base-moodle-color: #f88012;
$base-electric-blue-color: #1a6aff;
$base-black-color: #4d5156;
$base-grey-color: #687082;
$base-white-color: #fff;

$primary-color: $base-moodle-color;
$primary-color-hover: #e2750f;
$primary-color-active: #d46d0d;
$secondary-color: $base-white-color;
$tertiary-color: $base-grey-color;

$primary-hover-color: $base-electric-blue-color;

$error-color: $base-light-red-color;
$warning-color: $base-moodle-color;
$info-color: $base-electric-blue-color;
$success-color: $base-light-green-color;

$primary-text-color: $base-black-color;
