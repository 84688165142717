.sort-card {
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 0 6px #dedede;
  display: flex;

  > .title {
    font-size: 14px;
    font-weight: bold;
    width: 45px;
  }

  > .content {
    display: flex;
  }

  &.vertical {
    flex-direction: column;
    padding: 20px;

    > .content {
      flex-direction: column;
      margin-top: 16px;

      > :not(:last-child) {
        margin-bottom: 10px;
      }

      > .sort-button {
        width: 100%;
        > * {
          width: 100%;
        }
      }
    }
  }

  &.horizontal {
    padding: 10px 20px;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;

    > .content {
      gap: 15px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
