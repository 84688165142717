.view:has(.new-collection) {
}

.new-colleciton {
  width: 100%;
  max-width: 1100px;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    width: 100%;
    max-width: 1100px;
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}
