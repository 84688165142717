@import '../../../../styles/application';

.profile-card {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  padding-bottom: 26px;

  @media (max-width: 450px) {
    box-shadow: none;
    border-radius: 0;
    margin-top: 0;
  }

  > .background {
    cursor: pointer;
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-radius: 16px 16px 0 0;
    @media (max-width: 450px) {
      border-radius: 0;
    }
    @media (max-width: 550px) {
      height: 100px;
    }
    display: flex;
    justify-content: flex-end;
  }

  > .background-actions {
    position: absolute;
    display: flex;
    top: 10px;
    right: 10px;
    gap: 6px;
  }

  > .avatar {
    position: absolute;
    @media (max-width: 550px) {
      width: 120px;
      height: 120px;
      margin-top: -85px;
    }
    cursor: pointer;
    z-index: 3;
    border: 4px solid white;
    border-radius: 160px;
    width: 160px;
    height: 160px;
    object-fit: cover;
    margin-left: 24px;
    margin-top: -110px;
    display: flex;
    justify-content: flex-end;

    > .change-avatar-button {
      padding: 13px 5px 5px 5px;
      @media (max-width: 550px) {
        padding: 5px;
      }
    }
  }
  > .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px 0 0;
    height: 42px;
    > .edit-save {
      width: 40px;
      > *,
      > * > * {
        width: 100%;
        > svg {
          width: 18px;
          height: 18px;
        }
      }
      > .primary-button {
        display: grid;
        grid-template-columns: 1fr;
        > div {
          &.loading {
            display: flex;
            visibility: visible;
            width: 100%;
            align-items: center;
            justify-content: center;
          }
        }

        &.loading {
          pointer-events: none;
        }
      }
    }
  }

  > .info {
    padding: 18px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: 550px) {
      padding-top: 5px;
    }

    > .input-text-field,
    > .profile-card-header > .title > .input-text-field,
    > .profile-card-header > .subtitle > span > .input-text-field {
      > .no-edit {
        pointer-events: none;
        cursor: none;
      }
      > .input-text-field-container {
        box-shadow: none;
        animation: none;
      }
    }

    > .enter-error,
    > .profile-card-header > .title > .enter-error,
    > .profile-card-header > .subtitle > span > .enter-error {
      > .error-msg {
        height: 100%;
        font-weight: initial;
      }
    }

    > .profile-card-header > .subtitle > span > .enter-error {
      > .input-container {
        margin-top: 1px;
      }
    }

    > .profile-card-header {
      > .title {
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 22px;
        gap: 6px;

        > .display-name {
          width: fit-content;
          font-weight: 700;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        > .approved-icon {
          height: 22px;
          display: flex;
          align-items: center;
          > svg {
            height: 18px;
            width: 18px;
            &.zooom-in-enter-animation {
              animation: enter 1s;
            }

            @keyframes enter {
              0% {
                position: absolute;
                transform: scale(25);
                z-index: 10;
              }
              50% {
                transform: scale(0.5);
                position: absolute;
                z-index: 10;
              }
              74% {
                transform: scaleX(1);
                opacity: 1;
              }
              75% {
                transform: scaleX(-1);
                opacity: 0.3;
              }
              100% {
                opacity: 1;
                position: relative;
                height: 18px;
                z-index: inherit;
              }
            }
          }
        }

        > .user-id {
          > .copy-id {
            font-weight: normal;
            padding: 5px 12px;
            margin: -5px 0;
            height: fit-content;
          }
        }
      }

      > .title,
      .display-name > .input-container {
        height: auto;
        > input {
          display: flex;
          align-items: flex-start;
          width: 100%;
          gap: 10px;
          font-size: 22px;
          font-weight: bold;
        }
      }

      > .subtitle {
        margin-top: 3px;
        font-size: 13.5px;
        color: #00000099;
        display: flex;
        justify-content: flex-start;
        gap: 6px;

        @media (max-width: 450px) {
          flex-direction: column;
        }

        @media (min-width: 450px) {
          &:not(.edit) > *:not(:first-child):before {
            content: '·';
          }
        }

        > span,
        > a {
          display: flex;
          align-items: flex-start;
          > .input-text-field > .input-container {
            height: fit-content;
            > input {
              font-size: 13.5px;

              &::placeholder {
                font-size: 13.5px;
                color: #757575;
              }
            }
          }

          &:not(:first-child) {
            gap: 6px;
          }

          > .at-symbol {
            margin-right: 2px;
          }
        }
      }

      > .error {
        padding: 0 18px;
        width: fit-content;
        font-size: 14px;
        color: white;
        align-items: center;
        display: flex;
        background-color: #ff6262;
        border-radius: 20px;
        margin-top: 10px;
        height: 28px;
      }
    }

    > .description,
    .description > .input-container > textarea {
      font-size: 14px;
      line-height: 20px;
    }

    > .not-approved-warning {
      font-size: 13px;
      background-color: rgb(255 244 229);
      padding: 14px 18px;
      border-radius: 6px;
    }

    > .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;

      > .follow-button {
        > svg {
          width: 20px;
          height: 20px;
        }
      }

      > .following-button {
        gap: 2px;
        > svg {
          width: 17px;
          height: 17px;
        }
      }

      > .message {
        cursor: pointer;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;
      }

      > .floating-menu > .hover-element > .more {
        &.small {
          padding: 0 6px;
        }
        > .three-dots {
          margin-top: -12px;
          font-size: 20px;
        }
      }
    }
  }
}
