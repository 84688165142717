@import '../../../styles/application';

.dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  gap: 6px;

  > label {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 2px;
  }

  > .input-container {
    height: 46px;
    width: 100%;
    min-height: 44px;
    background: #ffffff;
    border: none;
    box-shadow: 0 0 6px #dedede;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    resize: none;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    font-weight: 500;
    font-size: 14px;

    > svg {
      display: block;
      margin-right: 6px;
      color: #b6bacb;
    }

    &.display-mode {
      padding: 0;
      background: none;
      box-shadow: none;

      > .icons {
        height: 24px;
        margin-left: 0;
        justify-content: start;
        > div > svg > g {
          fill: #4d5156;
        }
      }
    }

    > .dropdown-button {
      width: 100%;
      display: flex;
      border: none;
      background-color: transparent;
      align-items: center;
      padding: 8px 0 8px 12px;
      line-height: 20px;
      resize: none;
      color: rgba(0, 0, 0, 0.8);
      outline: none;
      font-weight: 500;
      font-size: 14px;

      &:focus {
        color: rgba(0, 0, 0, 0.87);
        box-shadow: none;
      }

      > .placeholder,
      &.input::placeholder {
        visibility: visible;
        font-weight: 500;
        font-size: 14px;
        color: #72777c;
      }
      &.input::-ms-.search-field-placeholder {
        visibility: visible;
        font-weight: 500;
        font-size: 14px;
        color: #72777c;
      }

      &.input:-ms-.search-field-placeholder {
        visibility: visible;
        font-weight: 500;
        font-size: 14px;
        color: #72777c;
      }

      &.single {
        overflow: hidden;
        > .dropdown-pill {
          text-overflow: ellipsis;
          width: 100%;
          > .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          > .round-button {
            display: none;
          }
        }
      }

      &.multiple {
        gap: 6px;
        > .dropdown-pill {
          display: flex;
          align-items: center;
          padding: 4px 5px 4px 14px;
          background-color: rgba(0, 0, 0, 0.08);
          color: #4d5156;
          cursor: pointer;
          border-radius: 30px;
          gap: 6px;

          > .label {
            white-space: nowrap;
          }

          > .round-button > svg {
            height: 19px;
            width: 19px;
            padding: 2px;
            border: none;
            background: #b3b3b3;
            color: white;

            &:hover {
              background: #8d8b8b;
            }
          }
        }

        &.multilines {
          flex-wrap: wrap;
          width: calc(100% - 36px);
          > .dropdown-pill {
            max-width: 100%;
            > .label {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &.display-mode {
        padding: 0;
        height: 24px;
        color: #4d5156;
        &:not(.not-editing) {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      > .icon,
      > .icon-text,
      .icons {
        width: 100%;
        height: 100%;
        //margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        div {
          height: 20px;
          display: flex;
          align-items: center;
          gap: 3px;
          > svg {
            width: fit-content;
            height: 100%;
            > g {
              fill: rgba(0, 0, 0, 0.8);
            }
          }
          &.icon-text {
            gap: 6px;
          }
        }

        &:is(.icon-text) {
          justify-content: flex-start;
          gap: 6px;
        }
      }

      > svg {
        display: block;
        margin: 0 6px;
        color: #757575;
      }
    }
  }

  > .dropdown-content {
    position: absolute;
    z-index: 3;
    top: 100%;
    background-color: white;
    box-shadow: 0 0 6px #dedede;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    padding: 9px 0;
    width: 100%;
    max-height: 160px;
    overflow-y: scroll;
    color: rgba(0, 0, 0, 0.87);

    > .option {
      cursor: pointer;
      padding: 9px 15px;
      font-size: 14px;
      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.04);
      }
      &:active {
        background-color: rgba(0, 0, 0, 0.12);
      }
      &.selected {
        background-color: rgba(25, 118, 210, 0.08);
        &:hover {
          background-color: rgba(25, 118, 210, 0.12);
        }
        &:active {
          background-color: rgba(25, 118, 210, 0.24);
        }
      }
      &.icon-and-text {
        display: flex;
        align-items: center;
        gap: 6px;
        :first-child {
          height: 20px;
          display: flex;
          align-items: center;
          gap: 3px;
          > svg {
            width: fit-content;
            height: 100%;
          }
        }
        > span {
          white-space: nowrap;
        }
      }
    }
  }

  > .error-msg {
    margin-left: 3px;
  }
}

.dropdown.disabled {
  > label {
    color: #c6c6c6;
  }
  > .input-container {
    border: #e9e9e97d 1px solid;
    background-color: #e8e8e8;
    box-shadow: 0 0 2px #dedede;
    pointer-events: none;

    > .dropdown-button {
      &::placeholder {
        visibility: hidden;
      }
    }
  }
}
