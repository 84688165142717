@import '../../../styles/utils/colors';

.progress-state {
  width: 100%;
  height: auto;
  margin: 40px 0;

  > .progress-header {
    margin-bottom: 16px;
    > .number {
      border-radius: 50%;
      width: 34px;
      line-height: 34px;
      margin-right: 8px;
      padding: 0px 6px;
      border: 2px solid #4d5156;
      text-align: center;
      font-size: 15px;
    }

    > .title,
    > .number {
      font-weight: bold;
    }

    > .subtitle {
      line-height: 26px;
      margin-left: 16px;
      color: #757575;
      font-size: 15px;
    }
  }
  > .progress-bar {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 3%;
    > * {
      flex: 1 1 0px;
      height: 9px;
      border-radius: 7px;
      &.done {
        background-color: #00bd7e;
      }
      &.current {
        background-color: #1a6aff;
      }
      &.todo {
        background: #e8e8e8;
      }
    }
  }
}
